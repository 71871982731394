<template>
  <div class="portfolio-list-component py-5">
    <div class="row">
      <div class="col-lg-9" :class="{ 'p-0': isMobile }">
        <hooper
          :infiniteScroll="false"
          :wheelControl="false"
          :trimWhiteSpace="true"
          :touchDrag="true"
          :centerMode="true"
          group="group1"
          class="w-100 h"
          :itemsToShow="1"
        >
          <slide
            class="w-100"
            v-for="item in portfolio.archives_portfolio"
            :key="item.id"
          >
            <div
              class="cover-big"
              :style="
                `background-image: url(${
                  Array.isArray(item.file) ? item.file[0].base64 : item.file
                })`
              "
            ></div>
          </slide>
          <hooper-navigation slot="hooper-addons"></hooper-navigation>
        </hooper>
      </div>
      <div class="col-lg-3 d-none d-lg-block">
        <hooper
          :infiniteScroll="false"
          :wheelControl="false"
          class="w-100 v"
          group="group1"
          :itemsToShow="3"
          :vertical="true"
          @slide="setColor"
          ref="carousel"
        >
          <slide
            class="my-3"
            v-for="(item, index) in portfolio.archives_portfolio"
            :key="item.id"
            :index="index"
          >
            <div
              :class="{ active: index == slide }"
              class="cover mb-0"
              :style="
                `background-image: url(${
                  Array.isArray(item.file) ? item.file[0].base64 : item.file
                })`
              "
            ></div>
          </slide>
          <hooper-navigation slot="hooper-addons"></hooper-navigation>
        </hooper>
      </div>
    </div>
    <hooper
      :infiniteScroll="false"
      :wheelControl="false"
      :trimWhiteSpace="true"
      group="group1"
      :itemsToShow="1"
      class="mt-3 h-100"
    >
      <slide
        class="w-100"
        v-for="item in portfolio.archives_portfolio"
        :key="item.id"
      >
        <p class="desc">{{ item.description }}</p>
      </slide>
    </hooper>
  </div>
</template>
<script>
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import "hooper/dist/hooper.css";
export default {
  components: {
    Hooper,
    Slide,
    HooperNavigation
  },
  data() {
    return {
      slide: 0
    };
  },
  props: {
    portfolio: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    isMobile() {
      if (window.innerWidth < 768) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    setColor() {
      this.slide = this.$refs.carousel.currentSlide;
      //console.log(this.$refs.carousel.currentSlide);
    }
  }
};
</script>
<style lang="scss" scoped>
.portfolio-list-component {
  .h {
    .cover-big {
      height: 0;
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      padding-bottom: 100%;
    }
    @media screen and (min-width: 992px) {
      height: 70vh;
    }
    ::v-deep .hooper-prev {
      padding: 0;
      background: rgba(251, 251, 251, 0.3);
      border-radius: 50%;
      margin-left: 10px;
      .icon-arrowLeft {
        width: 50px !important;
        height: 50px !important;
        fill: #ffcc00 !important;
      }
      @media screen and (max-width: 768px) {
        margin-left: 0;
        .icon-arrowLeft {
          width: 30px !important;
          height: 30px !important;
          fill: #ffcc00 !important;
        }
      }
    }

    ::v-deep .hooper-next {
      padding: 0;
      background: rgba(251, 251, 251, 0.3);
      border-radius: 50%;
      margin-right: 10px;

      .icon-arrowRight {
        width: 50px !important;
        height: 50px !important;
        fill: #ffcc00 !important;
      }
      @media screen and (max-width: 768px) {
        margin-right: 0;
        .icon-arrowRight {
          width: 30px !important;
          height: 30px !important;
          fill: #ffcc00 !important;
        }
      }
    }
  }
  .v {
    height: 70vh;
    ::v-deep .hooper-prev {
      padding: 0;
      right: 50% !important;
      left: 40% !important;
      .icon-arrowUp {
        width: 50px !important;
        height: 50px !important;
        fill: #ffcc00 !important;
        margin-top: -50px;
      }
    }

    ::v-deep .hooper-next {
      padding: 0;
      right: 50%;
      left: 40%;
      bottom: 0;

      .icon-arrowDown {
        width: 50px !important;
        height: 50px !important;
        fill: #ffcc00 !important;
        margin-bottom: -50px;
      }
    }
  }
  .cover {
    width: 100%;
    height: 0;
    padding-bottom: 70%;
    background-repeat: no-repeat;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    &.active {
      border: 1px solid #ffcc00;
    }
  }

  .desc {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.015em;
    color: #0a0a0a;
  }

  ::v-deep .hooper-next {
    &.is-disabled {
      display: none;
    }
  }

  ::v-deep .hooper-prev {
    &.is-disabled {
      display: none;
    }
  }
}
</style>
