<template>
  <div>
    <responsive-modal v-model="value">
      <div class="new-evaluate-modal p-2 px-lg-4">
        <h5 class="text-center text-dark text-uppercase font-weight-bold mb-4">
          Avaliar profissional
        </h5>
        <p class="font-weight-bold text-dark small">
          Você fechou negócio com esse(a) profissional?
        </p>

        <div class="result-box px-3 py-2 mb-4">
          <b-form-radio-group>
            <b-form-radio
              class="font-weight-bold small text-dark mr-3"
              v-model="form.closed_service"
              :value="true"
            >
              Sim, fechei negócio! 😃
            </b-form-radio>
            <b-form-radio
              class="font-weight-bold small text-dark"
              v-model="form.closed_service"
              :value="false"
            >
              Não fechei negócio 😕
            </b-form-radio>
          </b-form-radio-group>
        </div>
        <p class="font-weight-bold">Como você avalia o serviço prestado?</p>
        <div
          class="start-rating-wrapper d-flex align-items-center justify-content-between mb-0"
        >
          <button
            v-for="(n, i) in [1, 2, 3, 4, 5]"
            :key="`star-rating-${i}`"
            type="button"
            class="btn btn-star mb-0"
            @click="form.rating_star = n"
          >
            <i
              :class="{
                'icon-star-outline': n > form.rating_star,
                'icon-star-filled text-yellow': n <= form.rating_star
              }"
            ></i>
          </button>
        </div>
        <div
          class="start-rating-texts d-flex align-items-center justify-content-between mb-4"
        >
          <span>Não gostei</span>
          <span>Excelente!</span>
        </div>
        <form-group
          id="comment"
          v-model="form.comment"
          textarea
          :minHeight="100"
          placeholder="Digite um comentário sobre o serviço ou profissional"
          :errors="errors.comment"
          @change="$emit('resetErrors')"
          blackShape
        />
        <div class="d-flex align-items-center justify-content-end mb-4">
          <small class="font-weight-bold text-secondary">
            {{ form.comment.length }}/300 caracteres
          </small>
        </div>

        <div class="d-flex align-items-center justify-content-between">
          <button
            class="btn btn-h-sm btn-outline-secondary btn-block"
            @click="cancel"
          >
            Cancelar
          </button>
          <button
            @click="submit"
            :disabled="!isValidFields"
            class="btn btn-h-sm btn-primary btn-block ml-3 mt-0"
            :class="{
              'btn-secondary': !isValidFields
            }"
          >
            Confirmar
          </button>
        </div>
      </div>
    </responsive-modal>
    <confirmation
      iconType="check"
      title="Concluído!"
      v-model="success"
      confirmButtonClass="btn btn-primary"
      confirmButtonText="Fechar"
      text="Sua avaliação foi enviada com sucesso!"
      @confirm="confirmSuccess"
    />
  </div>
</template>
<script>
import { formFields } from "@/functions/helpers.js";
import { mapState } from "vuex";
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    profile: {
      type: Object,
      default: () => {}
    }
  },
  model: {
    prop: "value",
    event: "input"
  },
  watch: {
    value(v) {
      this.$emit("input", v);
    }
  },
  data() {
    return {
      ...formFields(["closed_service", "comment", "rating_star"]),
      success: false,
      rating_stars: []
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    isValidFields() {
      return (
        this.closed_service !== "" &&
        this.form.comment?.length &&
        this.form.rating_star
      );
    }
  },
  methods: {
    cancel() {
      this.onClose();
    },
    submit() {
      const user = this.user.id;
      const perfil = this.profile.id;

      this.showLoading = true;
      this.$store
        .dispatch("user/sendRating", {
          ...this.form,
          user,
          perfil
        })
        .then(() => {
          this.success = true;
          this.$emit("success-submit");
        })
        .catch(e => {
          if (e?.response?.data?.error === "Rating already exists!") {
            this.$message.error("Você já avaliou esse perfil!");
          } else {
            this.$message.error(
              e.response.data.status || e.response.data.error
            );
          }
        })
        .finally(() => {
          this.showLoading = false;
        });
    },
    onClose() {
      this.visible = false;
      this.$emit("input", false);
    },
    confirmSuccess() {
      this.$emit("success-submit");
      this.onClose();
    },
    resetErrors() {
      this.errors = {
        closed_service: [],
        comment: [],
        rating_star: [],
        non_field_errors: []
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.new-evaluate-modal {
  ::v-deep label {
    color: #202124 !important;
  }
  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.015em;
    color: #595959;
  }

  ::v-deep hr {
    border-top: 1px solid #8c8c8c !important;
  }

  .result-box {
    background: #fbfbfb;
    border-radius: 8px;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .start-rating-wrapper {
    i {
      font-size: 30px;
      color: #cfcfcf;
    }
  }

  .start-rating-texts {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    display: flex;
    align-items: center;
    letter-spacing: 0.015em;
    color: #595959;
  }

  .btn-star:hover {
    i {
      color: #ffcc00;
    }
  }
}
</style>
