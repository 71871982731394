<template>
  <div class="container">
    <div
      class="
        components-professional-profile
        d-flex
        justify-content-center
        px-2 px-lg-5
        mb-5 mt-lg-n5
        mt-4
      "
    >
      <div class="professional-profile-card px-3 px-lg-4  pt-lg-5 size">
        <router-link
          v-if="isCurrentUser && !isEditing"
          class="btn btn-edit mb-4"
          :to="{ name: 'my-professional-profile' }"
        >
          <i class="icon-edit" /> Editar perfil
        </router-link>
        <button
          @click="$emit('continue')"
          v-if="isEditing"
          class="btn btn-edit mb-4"
        >
          <i class="icon-edit"></i> Continuar Editando
        </button>
        <div class="header mb-4">
          <div class="row">
            <div class="col-2">
              <div class="profile-img-wrapper">
                <img
                  width="120"
                  height="120"
                  class="profile-img mb-3"
                  :src="profile.perfil_thumbnail || $user.placeholderUrl"
                  :alt="profile.name"
                />
                <img
                  width="43"
                  height="43"
                  class="emblem-img"
                  v-if="profile.emblem"
                  :src="profile.emblem.photo"
                  :alt="profile.emblem.name"
                />
              </div>
            </div>
            <div class="col-10 pl-5 mt-lg-3">
              <div class="d-flex mb-4">
                <div class="texts mr-4 pl-3 pl-lg-0">
                  <h3 class="company-name text-dark m-0">
                    {{ profile.name }}
                  </h3>
                  <div class="rating d-flex align-items-center">
                    <small
                      v-if="profile.company_name"
                      class="text-dark font-weight-bold mr-2"
                      >{{ profile.company_name }}</small
                    >
                    <i class="icon-star-filled mr-1"></i>
                    <small class="mt-1">{{
                      profile.rating_stars || "0.00"
                    }}</small>
                  </div>
                  <small v-if="profile.city" class="city"
                    >{{ profile.city }}/{{ profile.state }}</small
                  >
                  <div class="d-block">
                    <button
                      v-if="$user.user && profile.id != $user.profile.id"
                      @click="toEvaluate"
                      class="btn btn-primary d-md-none btn-evaluate px-4 mt-2"
                    >
                      Avaliar
                    </button>
                  </div>
                </div>
                <button
                  v-if="$user.user && profile.id != $user.profile.id"
                  @click="toEvaluate"
                  class="btn btn-primary d-none d-md-block btn-evaluate px-4 mt-n2"
                >
                  Avaliar
                </button>
              </div>

              <p v-if="profile.bio" class="bio d-none d-md-block">
                {{ profile.bio }}
              </p>

              <div class="social-media d-none d-md-block">
                <div class="row">
                  <div class="col-lg-11">
                    <div class="row">
                      <div v-if="profile.social_media" class="col-lg-5">
                        <a
                          :href="instagramUrl"
                          target="_blank"
                          class="contact instagram d-flex  align-items-center"
                        >
                          <div class="d-flex align-items-center">
                            <i class="icon-instagram"></i>
                            <span>{{ profile.social_media }}</span>
                          </div>
                        </a>
                      </div>
                      <div v-if="profile.site" class="col-lg-5">
                        <a
                          class="contact website d-flex  align-items-center"
                          :href="prepareSiteUrl(profile.site)"
                          target="_blank"
                        >
                          <div class="d-flex  align-items-center">
                            <i class="icon-website"></i>
                            <span>
                              {{ profile.site }}
                            </span>
                          </div>
                        </a>
                      </div>
                      <div v-if="profile.email" class="col-lg-5">
                        <a
                          :href="'mailto:' + profile.email"
                          target="_blank"
                          class="contact phone d-flex  align-items-center"
                        >
                          <i class="icon-email"></i>
                          <span>{{ profile.email }}</span>
                        </a>
                      </div>
                      <div v-if="profile.phone" class="col-lg-5">
                        <a
                          :href="
                            `https://api.whatsapp.com/send?phone=${formatPhone(
                              profile.phone
                            )}&text=Olá, vi seu perfil no Bora Conecta e entrei em contato!`
                          "
                          class="contact instagram d-flex  align-items-center"
                          target="_blank"
                        >
                          <i class="icon-whatsapp"></i>
                          <span>{{ profile.phone }}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p
              v-if="profile.bio"
              class="bio px-4 mx-1 mb-4 font-weight-bold d-md-none"
            >
              {{ profile.bio }}
            </p>

            <div class="social-media-mobile px-5 w-100 contact d-md-none">
              <div class="d-flex w-100">
                <i
                  v-if="profile.social_media"
                  @click="showInstagram = !showInstagram"
                  class="icon-instagram w-25"
                ></i>

                <i
                  v-if="profile.phone"
                  @click="showWhatsapp = !showWhatsapp"
                  class="icon-whatsapp w-25"
                ></i>
                <i
                  v-if="profile.site"
                  @click="showWebsite = !showWebsite"
                  class="icon-website w-25"
                ></i>
                <i
                  v-if="profile.email"
                  @click="showEmail = !showEmail"
                  class="icon-email w-25"
                ></i>
              </div>
            </div>
            <div class="position-relative w-100">
              <a
                v-show="showInstagram"
                :href="instagramUrl"
                target="_blank"
                class="btn btn-social position-absolute"
                >{{ profile.social_media }}</a
              >
              <a
                v-show="showWhatsapp"
                :href="
                  `https://api.whatsapp.com/send?phone=${formatPhone(
                    profile.phone
                  )}&text=Olá, vi seu perfil no Bora Conecta e entrei em contato!`
                "
                target="_blank"
                class="btn btn-social position-absolute"
                >{{ profile.phone }}</a
              >
              <a
                v-show="showWebsite"
                :href="prepareSiteUrl(profile.site)"
                target="_blank"
                class="btn btn-social position-absolute"
                >{{ profile.site }}</a
              >
              <a
                v-show="showEmail"
                :href="'mailto:' + profile.email"
                target="_blank"
                class="btn btn-social position-absolute"
                >{{ profile.email }}</a
              >
            </div>
          </div>
        </div>
        <div class="body mt-n3">
          <!-- <div v-if="profile.tags && profile.tags.length" class="mb-4">
            <span class="service-title mr-2 mb-3"> Serviços: </span>
            <span
              v-for="(tag, i) in profile.tags"
              :key="`tag-${i}`"
              class="tag mr-1"
            >
              {{ tag.name }}
            </span>
          </div> -->

          <div class="tab-view">
            <div
              class="d-flex align-items-center justify-content-between justify-content-lg-start"
            >
              <button
                :class="
                  `btn btn-tab-option font-weight-bold ${
                    active_tab === 0 ? 'active' : ''
                  }`
                "
                @click="active_tab = 0"
              >
                Serviços
              </button>
              <button
                :class="
                  `btn btn-tab-option font-weight-bold ${
                    active_tab === 2 ? 'active' : ''
                  } `
                "
                @click="active_tab = 2"
              >
                Sobre mim
              </button>
              <button
                :class="
                  `btn btn-tab-option font-weight-bold ${
                    active_tab === 1 ? 'active' : ''
                  }`
                "
                @click="active_tab = 1"
              >
                Avaliações
              </button>
              <button
                :class="
                  `btn btn-tab-option font-weight-bold ${
                    active_tab === 3 ? 'active' : ''
                  }`
                "
                @click="active_tab = 3"
              >
                Portfolio
              </button>
            </div>
            <div class="tab-content">
              <div v-if="active_tab == 0">
                <service-list class=" py-lg-4" :services="profile_services" />
              </div>
              <div v-if="active_tab == 1">
                <rating-list
                  class="p-lg-4 p-3"
                  :ratings="profile_ratings"
                  @view-more="getRatings"
                />
              </div>
              <div v-if="active_tab == 2">
                <div
                  v-if="profile.about"
                  v-html="profile.about"
                  class="about text-secondary p-lg-4 p-3 py-lg-4"
                ></div>

                <p v-else class="about p-4 text-secondary py-lg-4">
                  Este profissional ainda não cadastrou texto sobre ele
                </p>
              </div>
              <div v-if="active_tab == 3">
                <div v-if="isEditing">
                  <portfolio-list :portfolio="portfolioEdit" />
                </div>
                <div v-else-if="portfolio">
                  <portfolio-list :portfolio="portfolio" />
                </div>
                <p v-else class="about p-4 text-secondary py-lg-4">
                  Este profissional ainda não cadastrou um portfolio
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <new-evaluate-modal
        v-model="evaluateVisible"
        :profile="profile"
        :has-other-page="has_other_rating_page"
        @success-submit="updateProfileData"
      />
    </div>
  </div>
</template>
<script>
import NewEvaluateModal from "@/components/evaluate/NewEvaluateModal";
import ServiceList from "@/components/professional/ServiceList";
import RatingList from "@/components/professional/RatingList";
import PortfolioList from "@/components/professional/PortfolioList.vue";
import { removeMask } from "@/functions/helpers.js";
import { mapState } from "vuex";

export default {
  components: {
    NewEvaluateModal,
    RatingList,
    ServiceList,
    PortfolioList
  },
  props: {
    profile: {
      type: Object,
      default: () => {}
    },
    isEditing: {
      type: Boolean,
      default: false
    },
    portfolioEdit: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      active_tab: 0,
      evaluateVisible: false,
      profile_ratings: [],
      profile_services: [],
      has_other_rating_page: true,
      about: [],
      showInstagram: false,
      showEmail: false,
      showWebsite: false,
      showWhatsapp: false
    };
  },
  methods: {
    formatPhone(phone) {
      const formatted = removeMask(phone);
      return formatted.slice(0, 2) == "55" ? formatted : `55${formatted}`;
    },
    prepareSiteUrl(site) {
      if (!site || !site.length) return "";

      if (site.indexOf("http:") != -1) {
        const fixed = site.replace("http", "https");
        return fixed;
      }

      if (site.indexOf("https://") == -1) return `https://${site}`;

      return site;
    },
    toEvaluate() {
      if (!this.canEvaluate) return;

      this.evaluateVisible = true;
    },
    getRatings(offset) {
      const profile = this.profile.id;

      this.showLoading = true;
      this.$store
        .dispatch("user/getPerfilRatings", {
          profile,
          offset: offset ?? 0
        })
        .then(({ results, next }) => {
          this.success = true;
          this.has_other_rating_page = !!next;
          if (offset && results.length) {
            this.profile_ratings.push(results);
          } else if (results.length) {
            this.profile_ratings = results;
          }
        })
        .catch(e => {
          this.$message.error(e.response.data.status || e.response.data.error);
        })
        .finally(() => {
          this.showLoading = false;
        });
    },
    getPerfilServices() {
      this.showLoading = true;
      this.$store
        .dispatch("services/getServicesUser", this.$user.user.id)
        .then(({ results }) => {
          this.success = true;
          this.profile_services = this.serviceList;
        })
        .catch(e => {
          this.$message.error(e.response.data.status || e.response.data.error);
        })
        .finally(() => {
          this.showLoading = false;
        });
    },
    updateProfileData() {
      this.getPerfilServices();
      this.getRatings();
      this.$emit("update-profile-data");
    }
  },
  computed: {
    ...mapState("services", ["serviceList"]),
    ...mapState("portfolio", ["portfolio"]),
    isCurrentUser() {
      return this.$user.profile?.slug == this.$route.params.slug;
    },
    instagramUrl() {
      if (!this.profile.social_media || !this.profile.social_media.length)
        return "";

      return `https://instagram.com/${this.profile.social_media.replace(
        "@",
        ""
      )}`;
    },
    canEvaluate() {
      // A logged user can't evaluate himself
      if (this.$user.profile.id && this.$user.profile.id === this.profile.id) {
        this.$message.error("Você não pode avaliar a sí mesmo");
        return false;
      }

      return true;
    },
    occupation() {
      return this.profile.category.name;
    }
  },
  mounted() {
    Promise.all([
      this.getRatings(),
      this.getPerfilServices(),
      this.$store.dispatch("portfolio/getThirdPortfolio", this.profile.id)
    ])
      .catch(errors => errors.map(err => this.$message.error(err)))
      .finally(() => (this.loading = false));
  },
  watch: {
    showInstagram() {
      if (this.showInstagram) {
        setTimeout(() => {
          this.showInstagram = !this.showInstagram;
        }, 1300);
      }
    },
    showWhatsapp() {
      if (this.showWhatsapp) {
        setTimeout(() => {
          this.showWhatsapp = !this.showWhatsapp;
        }, 1300);
      }
    },
    showWebsite() {
      if (this.showWebsite) {
        setTimeout(() => {
          this.showWebsite = !this.showWebsite;
        }, 1300);
      }
    },
    showEmail() {
      if (this.showEmail) {
        setTimeout(() => {
          this.showEmail = !this.showEmail;
        }, 2500);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.container {
  max-width: 1200px;
}
.components-professional-profile {
  @media screen and (min-width: 1700px) {
    padding-top: 5rem !important;
  }
  @media screen and (max-width: 991px) {
    padding-top: 5rem !important;
  }
  @media screen and (max-width: 768px) {
    padding-top: 1.5rem !important;
  }
  .professional-profile-card {
    position: relative;
    &.size {
      width: 100%;
    }
    .btn-edit {
      position: absolute;
      top: 1%;
      right: 3%;
      color: $secondary;
      display: flex;
      align-items: center;
      i {
        font-size: 28px;
        color: $secondary;
      }

      @media screen and (max-width: 768px) {
        top: -1%;
        right: 1%;
        font-size: 12px;
        i {
          font-size: 20px;
        }
      }
    }
    .fw-semibold {
      font-weight: 500;
    }

    ::v-deep .fw-bold-deep {
      font-weight: bold;
      font-size: 14px;
      white-space: pre-wrap;
      & > * {
        font-weight: bold;
      }
    }

    background: #f5f5f5;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
    border-radius: 16px;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    padding: 24px;
    z-index: 3;
    margin-top: -18%;

    .header {
      .profile-img {
        height: 120px;
        width: 120px;

        @media screen and (max-width: 768px) {
          height: 90px;
          width: 90px;
        }
      }

      .company-name {
        font-size: 18px;
        line-height: 150%;
        @media screen and (max-width: 768px) {
          font-size: 14px !important;
          word-break: break-all;
        }
      }

      .occupation-name {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.015em;
        color: #202124;
      }
      .profile-img-wrapper {
        position: relative !important;
        .mentoring-tag {
          letter-spacing: 0.015em;
          position: absolute;
          font-style: normal;
          line-height: 150%;
          font-weight: 500;
          font-size: 14px;
          bottom: 0;
          right: -5%;
        }
      }
      .emblem-img {
        position: absolute;
        left: 80px;
        top: 80px;
        @media screen and (max-width: 767px) {
          top: 70px;
          left: 55px;
        }
      }

      .rating {
        color: $primary;
        i {
          font-size: 20px;
        }
        small {
          font-size: 14px;
          line-height: 150%;
          letter-spacing: 0.015em;
          color: #ffcc00;
        }
      }
      .btn-evaluate {
        max-height: 33px !important;
        font-weight: 700;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.015em;
        color: #0a0a0a;
      }

      .bio {
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.015em;
        color: #0a0a0a;
      }
      .contact {
        word-break: break-all;
        cursor: pointer;
        text-decoration: none;
        margin-bottom: 8px;

        span {
          font-style: normal;
          font-weight: bold;
          font-size: 10px;
          line-height: 100%;
          letter-spacing: 0.015em;
          color: #0a0a0a;
          //margin-top: 0.3rem;
        }
        i {
          font-size: 22px;
          color: #737373;
          margin-right: 6px;
        }
      }
    }
    .body {
      .professional-rating {
        & > * {
          color: #ffcc00;
        }
        i {
          font-size: 24px;
        }
        span {
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 150%;
          letter-spacing: 0.015em;
        }

        .count {
          color: #202124;
          font-weight: 500;
        }
      }

      .fit-content {
        width: fit-content !important;
      }

      .tab-view {
        .btn-tab-option {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 150%;
          text-align: center;
          letter-spacing: 0.015em;
          color: #595959;
          border: 0;
          border-radius: 0;
          white-space: nowrap;
          margin-left: 20px;
          padding: 0 10px;

          &.active {
            color: #0a0a0a;
            border-bottom: 2px solid #ffcc00;
          }

          @media screen and (max-width: 992px) {
            font-size: 12px;
            padding: 0 5px;
            margin-left: 0px;
          }
        }
        .tab-content {
          padding: 20px 0px;
        }

        .about {
          font-weight: 500;
          font-size: 14px;
          line-height: 150%;
          letter-spacing: 0.015em;
          color: #0a0a0a;
        }
      }
    }
  }
  .city {
    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.015em;
    color: #595959;
  }

  .btn-social {
    background: #0a0a0a;
    border-radius: 4px;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.015em;
    color: #efefef;
    z-index: 999;
    left: 50%;
    transform: translateX(-50%);
    &:active {
      background: #333333;
      border-radius: 4px;
      color: #ffcc00;
    }
  }
}
</style>
