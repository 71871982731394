<template>
  <div class="components-professional-rating-list">
    <div v-if="ratings.length">
      <div v-for="(rat, i) in ratings" :key="`service-${i}`" class="row mb-5">
        <div class="col-lg-2 text-center text-lg-right">
          <img
            class="profile-img mb-4 mb-lg-0"
            :src="rat.perfil_thumbnail || $user.placeholderUrl"
            :alt="rat.name"
          />
        </div>
        <div class="col-lg-10">
          <div class="d-none d-lg-flex align-items-center">
            <h5 class="py-0 my-0 mr-2 text-dark font-weight-bold">
              {{ rat.name }}
            </h5>
            <div class="d-flex align-items-center">
              <i
                v-for="(r, indexStarts) in parseInt(rat.rating_star)"
                :key="`${indexStarts}`"
                class="icon-star-filled text-yellow"
              ></i>
            </div>
          </div>
          <div class="d-lg-none text-center">
            <div class="d-flex align-items-center justify-content-center">
              <i
                v-for="(r, indexStarts) in parseInt(rat.rating_star)"
                :key="`${indexStarts}`"
                class="icon-star-filled text-yellow"
              ></i>
            </div>
            <h5 class="py-0 my-0 mr-2 text-dark font-weight-bold">
              {{ rat.name }}
            </h5>
          </div>
          <p class="text-dark text-center text-lg-left mb-0">
            <small>
              <span v-html="rat.comment"></span>
            </small>
          </p>
        </div>
      </div>
      <div v-if="hasOtherPage" class="d-flex justify-content-center">
        <button class="btn btn-outline-dark" @click="viewMore">
          Carregar mais
        </button>
      </div>
    </div>
    <div v-else>
      <p class="text-secondary about">
        Este profissional ainda não foi avaliado
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    ratings: {
      type: Array,
      default: () => []
    },
    hasOtherPage: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    viewMore() {
      this.$emit("view-more", this.ratings.length);
    }
  }
};
</script>
<style lang="scss" scoped>
.components-professional-rating-list {
  .profile-img {
    height: 72px;
    width: 72px;

    @media (min-width: 992px) {
      height: 60px;
      width: 60px;
    }
  }

  .about {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.015em;
    color: #0a0a0a;
  }
}
</style>
