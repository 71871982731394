<template>
  <div class="components-professional-service-list">
    <div
      class="focused-service text-left px-lg-4 container"
      v-if="serviceInFocus"
    >
      <div class="d-flex justify-content-start">
        <button
          class="btn text-uppercase text-left p-0 mb-4 mr-2"
          @click="serviceInFocus = null"
        >
          <i class="icon-return text-primary font-weight-bold"></i>
        </button>
        <span class="font-weight-bold text-dark text-justify">
          {{ serviceInFocus.certificate.title }}
        </span>
      </div>
      <div>
        <carousel
          navigationNextLabel="<i class='icon-arrow-right'/>"
          navigationPrevLabel="<i class='icon-arrow-left'></i>"
          :navigationEnabled="true"
          :perPage="1"
          :autoplay="true"
          :paginationEnabled="false"
          v-if="serviceInFocus.archives_service || service.url.length"
        >
          <slide
            v-for="(item, i) in serviceInFocus.archives_service"
            :key="i"
            class=""
          >
            <div
              class="imgs-carousel"
              :style="`background-image:url(${item.file})`"
            ></div>
          </slide>
          <slide v-if="serviceInFocus.url && serviceInFocus.url.length">
            <div class="embed-responsive embed-responsive-16by9">
              <iframe
                class="embed-responsive-item"
                :src="embedTransform(serviceInFocus.url)"
              >
              </iframe>
            </div>
          </slide>
        </carousel>
        <div
          v-html="description"
          class="service-description text-dark text-justify small mt-3"
        ></div>
      </div>
    </div>
    <div
      class="row align-items-center"
      :class="{
        'justify-content-center': services.length >= 3
      }"
      v-else
    >
      <template v-if="services.length">
        <div
          v-for="(serv, j) in services"
          :key="`service-${j}`"
          class="col-lg-3 mb-4 mb-lg-3 d-flex justify-content-center"
        >
          <button class="btn p-0 m-0" @click="serviceInFocus = serv">
            <div class="card">
              <div
                class="card-body w-100 p-0 d-flex flex-column justify-content-end"
              >
                <div class="w-100">
                  <div
                    class="cover"
                    :style="`background-image: url(${serv.certificate.cover});`"
                  ></div>
                  <h4 class="occupation-title text-dark text-left padding-text">
                    {{ serv.certificate.title }}
                  </h4>
                </div>
              </div>
            </div>
          </button>
        </div>
      </template>
      <div v-else class="pl-3 ">
        <p class="text-secondary about">
          Este profissional ainda não cadastrou nenhum serviço
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    services: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      serviceInFocus: null,
      carouselIndex: 0
    };
  },
  computed: {
    description() {
      if (this.serviceInFocus.certificate.description) {
        let msg = this.serviceInFocus.certificate.description.replace(
          /\n/g,
          "<br/>"
        );
        return msg;
      } else {
        return " ";
      }
    }
  },
  methods: {
    embedTransform(url) {
      let s = url.replace("watch?v=", "embed/");
      return s;
    }
  }
};
</script>
<style lang="scss" scoped>
.components-professional-service-list {
  .btn {
    font-size: 18px;
    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }
  .card {
    width: 100%;
    max-width: 300px;
    min-height: 200px;
    @media screen and (max-width: 768px) {
      width: 100%;
      min-height: 240.98px;
    }

    @media screen and (max-width: 500px) {
      max-width: 500px;
    }

    border-radius: 13.8133px;
    border: none;
    .card-img {
      width: 219px;
      border-radius: 13.8133px 13.8133px 0px 0px;
    }
    .card-body {
      background: #fbfbfb;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
      border-radius: 16px;
      padding: 0.7rem;
      margin-right: 24px;
      min-height: 240px;

      /* .occupation-title {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      } */
      .occupation-title {
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.015em;
        color: #0a0a0a;
      }

      .padding-text {
        padding: 0.7rem;
      }

      .serv-description {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        line-clamp: 4;
        -webkit-box-orient: vertical;

        font-size: 14px;
        text-align: start;
        font-weight: normal;
      }

      .cover {
        border-radius: 13.8133px 13.8133px 0px 0px;
        width: 100% !important;
        height: 0;
        padding-bottom: 60%;
        background-size: cover;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;

        @media screen and (max-width: 768px) {
          padding-bottom: 50%;
        }

        &.wo-photo {
          background-color: #ffcc00;
        }
      }
    }
  }

  .imgs-carousel {
    width: 100%;
    height: 0;
    padding-bottom: 60%;
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    @media screen and (max-width: 768px) {
      width: 100%;
      margin: 0 auto;
    }
  }

  .focused-service {
    position: relative;
    ::v-deep .service-description {
      word-break: break-word !important;
    }
  }

  ::v-deep .VueCarousel-navigation-button {
    background-color: rgba(251, 251, 251, 0.5);
    width: 45px;
    height: 45px;
    border-radius: 50%;
    i {
      font-size: 30px;
      color: #ffcc00;
    }
    @media screen and (max-width: 768px) {
      width: 40;
      height: 40px;
      z-index: 9999;
      i {
        font-size: 25px;
      }
    }
  }
  .about {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.015em;
    color: #0a0a0a;
  }
}
::v-deep .VueCarousel-navigation-prev {
  margin-left: 3.5rem;
}
::v-deep .VueCarousel-navigation-next {
  margin-right: 3.5rem;
}
::v-deep .VueCarousel-navigation--disabled {
  display: none;
}
</style>
